import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","z-index":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"v-input--search",attrs:{"label":"","placeholder":"Search Family Members","prepend-inner-icon":"mdi-magnify","filled":"","rounded":"","dense":"","clearable":""},on:{"click:clear":_vm.clearSearchAction,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchAction()}},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('FamilyMemberSearchForm',{attrs:{"meta":_vm.meta},on:{"clicked-search-button":_vm.clickedSearchButtonInModal}})],1),_c(VSpacer)],1),_c('Paginate',{attrs:{"meta":_vm.meta},on:{"paginate":_vm.paginateAction}}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.familyMembers,"options":_vm.options,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.card_expire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.card_expire))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VSpacer),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])}),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }