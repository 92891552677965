<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        z-index="20"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="search.keyword"
            label=""
            placeholder="Search Family Members"
            prepend-inner-icon="mdi-magnify"
            filled
            rounded
            dense
            clearable
            v-bind="attrs"
            v-on="on"
            class="v-input--search"
            @click:clear="clearSearchAction"
            @keydown.enter="searchAction()"
          ></v-text-field>
        </template>
        <FamilyMemberSearchForm @clicked-search-button="clickedSearchButtonInModal" :meta="meta" />
      </v-menu>
      <v-spacer></v-spacer>
    </v-toolbar>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <v-data-table
      :headers="headers"
      :items="familyMembers"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.card_expire="{ item }">
        {{ formatDate(item.card_expire) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-spacer></v-spacer>
        <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<style scoped>
.v-input--search {
  max-width: 80%;
}
.v-btn--new {
  position: absolute;
  top: -12px;
  right: 0;
}
.v-menu__content {
  max-width: 640px;
  padding: 20px 20px 0 20px;
  background: white;
}
</style>
<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import { dateMixin } from '@/mixins/dateMixin';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import FamilyMemberSearchForm from '@/views/FamilyMemberSearchForm';
import Paginate from '@/views/Paginate';

export default {
  name: 'FamilyMembersView',
  components: {
    FamilyMemberSearchForm,
    Paginate,
    ConfirmDialog
  },
  mixins: [dateMixin],
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('family/list', {
        page: currentPage,
        sort_by: 'id',
        sort_desc: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  methods: {
    load() {
      store.dispatch('family/list', {
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'surname',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    editItem(item) {
      this.$router.push({ path: `/family-members/${item.id}` });
    },
    deleteItem(item) {
      this.family_member_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('family/delete', this.family_member_id);
      this.load();
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('family/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('family/paginate', this.links.prev);
      }
    },
    clickedSearchButtonInModal(options) {
      this.menu = false;
      const defaultOptions = {
        active: true,
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'surname',
        sort_desc: this.options.sortDesc[0] ?? false
      };
      this.searchQuery({
        ...defaultOptions,
        ...options
      });
    },
    searchAction() {
      this.searchQuery({
        keyword: this.search.keyword,
        active: true,
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'surname',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    searchQuery(options) {
      Object.keys(options).forEach(key => {
        if (options[key] === null || options[key] === undefined) {
          delete options[key];
        }
      });
      store.dispatch('family/search', options);
      this.search = options;
      this.menu = false;
    },
    clearSearchAction() {
      this.search = {
        keyword: '',
        active: false
      };
      this.load();
    }
  },
  computed: {
    ...mapGetters('family', ['loading', 'error', 'familyMembers', 'meta', 'links'])
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          if (this.search.active) {
            this.search.page = this.options.page;
            this.search.sort_by = this.options.sortBy[0] ?? 'surname';
            this.search.sort_desc = this.options.sortDesc[0] ?? false;
            this.searchQuery(this.search);
          } else {
            this.load();
          }
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    menu: false,
    search: {
      keyword: '',
      active: false
    },
    showDeleteConfirmDialog: false,
    options: {},
    headers: [
      { text: 'Firstname', value: 'first_name' },
      { text: 'Surname', value: 'surname' },
      { text: 'Status', value: 'status' },
      { text: 'Relationship', value: 'relationship' },
      { text: 'Card#', value: 'card_number' },
      { text: 'Expire', value: 'card_expire' },
      { text: 'Family', value: 'organisation', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
